
<template>
  <div class="sb-media" :class="myClasses" v-editable="editing && blok" ref="animation_target">
    <div class="sb-media__inner" v-if="!svg_data && !loading">
      <div class="controls" v-if="is_video">
        <div class="controls__play-icon" v-html="icons['/assets/images/icons/icon_play_button.svg']"></div>
      </div>
      <video v-if="is_video && video_file" :src="video_file"
        :poster="has_poster && blok.file?.filename + '/m/1600x0/filters:quality(80)' || ''"
        :muted="blok.video_settings?.includes('muted') || props.blok.is_background"
        :controls="blok.video_settings?.includes('controls')" 
        :loop="blok.video_settings?.includes('loop')"
        :playsinline="props.blok.is_background || blok.video_settings?.includes('playsinline')"
        :autoplay="blok.video_settings?.includes('autoplay') || props.blok.is_background"
        controlsList="nodownload"></video>
      <img v-if="is_image && blok.file.filename && !svg_data && !is_embed && !is_video"
        :class="{ 'is-poster': has_poster }" :src="blok.file.filename + image_compression_default"
        :alt="blok.file?.alt" />
      <iframe v-if="is_embed" :src="blok.video_embed" frameborder="0"></iframe>
    </div>
    <div class="sb-media__inner" v-if="svg_data && !loading" v-html="svg_data"></div>
  </div>
</template>

<script setup>
const props = defineProps({ blok: Object });
// console.log(props.blok)
const { editing,image_compression_default } = useSettings();
const animation_target = ref(null)
const {icons,uiconfig,handleAnimation} = useUIControl()
const layout = props.blok.layout;
const { getLayoutClasses } = useLayoutControl();
const layout_classes = getLayoutClasses(props.blok);
const image_match = /([a-zA-Z0-9\s_\\.\-:])+(.png|.jpg|.gif|.svg|.webp)$/;
const video_match = /([a-zA-Z0-9\s_\\.\-:])+(.mp4|.WebM)$/;
const is_image = props.blok.file.filename?.match(image_match) && true;
const is_video =
  (props.blok.file.filename?.match(video_match) && true) ||
  props.blok.video_file;
const is_embed = props.blok.video_embed.length > 0;
const video_file =
  props.blok.video_file ||
  (props.blok.file.filename?.match(video_match) && props.blok.file.filename);
const has_poster =
  props.blok.video_embed.length > 0 &&
  props.blok.file.filename?.match(image_match);
//
let svg_data = false;
let loading = true;
// console.log(props)
//console.log(props.blok.video_settings)
//console.log(props.blok)
// if (video_file) console.log(video_file);
// console.log(icons.value)
// console.log(props.blok.file?.filename)
if (props.blok.file.filename?.includes(".svg")) {
  await fetch(
    props.blok.file.filename.replace("https://", "https://s3.amazonaws.com/")
  )
    .then((res) => res.text())
    .then((svg) => (svg_data = svg));
  loading = false;
} else {
  loading = false;
}
//let logo_data = ""

//await fetch(props.blok.file.filename.replace("https://","https://s3.amazonaws.com/")).then(res => res.text()).then(svg => logo_data = svg)
onMounted(() => {
  // console.log(layout.a_enabled)
  if(layout?.a_enabled)handleAnimation(animation_target,{layout:props.blok.layout,type:'media',a:{...props.blok.layout.animation}});
})

const myClasses = computed(() => {
  //  console.log()
  return [
    {
      "sb-media--image": is_image,
      "sb-media--video": is_video,
      "sb-media--video-embed": is_embed,
      "sb-media--has-poster": has_poster,
      "sb-media--is-background": props.blok.is_background,
      "sb-media--animated":uiconfig.animation && props.blok?.is_animated
    },
    ...layout_classes,
  ];
});
</script>
<style lang="scss">

</style>